import React, { FC, useCallback } from 'react';
import { useDomains } from '@/controllers/subDomain/subDomain.hooks/useDomains';
import { changeLanguage, useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { i18nIsGMDomain } from '@/middleware/i18n/i18n.utils/i18n.isGMDomain';
import { LanguageName, useUpdateUserDomainAndLanguageMutation } from '@/controllers/graphql/generated';
import { useDomainLanguage } from '@/controllers/language/useDomainLanguage';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { IconRoundCountry } from '@/components/landing/LandingHeader/components/HeaderLanguageSelector/icons/IconRoundCountry';
import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { cn } from '@/lib';
import { analyticsSDK } from '@/controllers/analytics';
import { I18N_CODES } from '@/lib/constants/general';
import { emptyFunction } from '@/lib/helpers/functional';
import { isSubscriptionProductDomain } from '@/controllers/subDomain/subDomain.utils/isSubscriptionProductDomain';
import { ROUTES } from '@/controllers/router/router.contants';
import styles from './HeaderLanguageSelector.module.scss';

const DomainAvailableLanguages: Record<SubDomains | string, LanguageName[]> = {
  [SubDomains.br]: [LanguageName.Pt],
  [SubDomains.pl]: [LanguageName.Pl, LanguageName.En],
  [SubDomains.ua]: [LanguageName.Uk, LanguageName.En],
  [SubDomains.us]: [LanguageName.En],
};

export const DomainSelectorModal: FC = () => {
  const [domains] = useDomains();
  const [authUser] = useAuthUser({ ssr: true });
  const { t } = useTranslation([I18N_CODES.common]);
  const { subDomain, language: userLanguage } = useSubDomainContext();

  const renderedDomains = domains.filter((item) => !i18nIsGMDomain(item.slug));

  const getDomainLanguage = useDomainLanguage();

  const [updateDomainAndLanguage] = useUpdateUserDomainAndLanguageMutation();

  const changeDomain = useCallback(async (
    domain: string,
    lang?: LanguageName,
  ) => {
    const language = lang || getDomainLanguage(domain);

    if (authUser) {
      await updateDomainAndLanguage({
        variables: {
          domainSlug: domain,
          languageName: language,
        },
      });

      analyticsSDK.landing.sendUserDomainChangedEvent({
        oldDomain: String(subDomain),
        newDomain: domain,
        source: 'country_selector',
      });
    }

    const redirectUrl = isSubscriptionProductDomain(domain)
      ? ROUTES.home
      : undefined;

    changeLanguage({
      domain,
      language,
      redirectUrl,
    });
  }, [
    getDomainLanguage,
    updateDomainAndLanguage,
    authUser,
    subDomain,
  ]);

  const handleButtonClick = useCallback((event, domain, lang) => {
    event.stopPropagation();
    changeDomain(domain, lang);
  }, [changeDomain]);

  return (
    <>
      {renderedDomains.map(({ slug }) => (
        <div
          onClick={() => changeDomain(slug)}
          role='button'
          onKeyPress={emptyFunction}
          tabIndex={0}
          key={slug}
          data-qa="domain-selector-item"
          className={cn(styles.domainSelectorItem, {
            [styles.selectedItem]: slug === subDomain,
          })}
        >
          <IconRoundCountry country={slug} />

          {t(`${I18N_CODES.common}:language_selector.country_${slug}`)}

          <div className={styles.languageButtonContainer}>
            {DomainAvailableLanguages[slug]?.map((lang) => (
              <Button
                key={lang}
                onClick={(event) => handleButtonClick(event, slug, lang)}
                mode={Button.mode.Secondary}
                data-qa="language-selector-item"
                text={t(`${I18N_CODES.common}:language_selector.language_${lang}`)}
                className={cn(styles.languageButton, {
                  [styles.selectedLanguage]: (
                    slug === subDomain && lang === userLanguage
                  ),
                })}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
